import { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { FaceSadTear } from '@styled-icons/fa-regular/FaceSadTear';
import { useTranslations } from 'next-intl';

import Button from '@/components/button';
import Bugsnag from '@/bugsnag';

const Container = styled.div`
  margin: 0 auto;
  max-width: 25em;
  text-align: center;
  margin: 10em auto;
`;

const SadFaceIcon = styled(FaceSadTear)`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 3em;
`;

export default function FourOhFour () {
  const router = useRouter();
  const t = useTranslations('404');

  useEffect(() => {
    Bugsnag.notify(new Error('Page not found', `Page '${router.asPath}' was not found`));
  }, [router.asPath]);

  return <>
    <Container>
      <h1>{t('title')}</h1>
      <SadFaceIcon size={100} />
      <div>
        <Link href="/buy" passHref>

          <Button primary>{t('browseAll')}</Button>

        </Link>
      </div>
    </Container>
  </>;
}

export async function getStaticProps ({ locale }) {
  return {
    props: {
      messages: require(`@/locales/${locale}.json`)
    }
  };
}
